const debug = false

const url_images = window.location.protocol + "//" + window.location.host + "/img"

const datos = {
    urlapi: debug === true ? 'http://localhost:4000/api/1.0' : 'https://back.wesyncro.click/api/1.0',
    url_images,
    key_local_storage_user: 'lm_delpa_user',
    key_local_storage_config: 'lm_delpa_config',
    key_local_storage_permissions: 'lm_delpa_access',
    pagina: 20,
    maps_key: "AIzaSyA2UVu71Lhw-lWjuTloT-VGIylp_63lgRY",
    timezone: "America/Santiago",
    configuracion_visual: {
        grafico_vehiculo_proveedor: true,
        grafico_general: true,
        eventos_conductores: true,
    },
    interfaz:{
        tamano_letra_tabla: 13,
        tabla_cabecera:{
            background: "#e0e0e0", 
            border: "none"
        },
        tabla_cabecera_texto: {
            fontSize: 13,
            color:"#616161"
        },
    },
    config_s3: {
        dirName: "tremus/proveedores",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    }
}

module.exports = datos