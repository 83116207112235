import { Badge, Box, Button, ButtonGroup, Card, CardBody, Container, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormLabel, Grid, GridItem, Heading, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, SimpleGrid, Skeleton, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../Header'
import { formatearRUTChileno } from '../../lib/helpers/data/chile'
import { ExternalLinkIcon, LockIcon, RepeatClockIcon, StarIcon, WarningIcon } from '@chakra-ui/icons'
import { urlapi } from '../../lib/backend/data'
import { tremus_style } from '../../lib/global/style'
import { stringByStatus } from '../../lib/helpers/empresa/empresas'
import ListadoProductos from './listado'
import Structure from '../Structure'
import { AiOutlinePlus, AiOutlineShop } from 'react-icons/ai'
import { RiFileExcelLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { rutas } from '../../lib/global/rutas'
// import StatusBusiness from '../Home/statusBusiness'

const Productos = (props) => {
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ producto, setProducto ] = useState({
        stock_type: "simple"
    })
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ permission, setPermision ] = useState(false)
    const [ usuario, setUsuario] = useState(false)
    const [ resetListado, setResetListado] = useState(0)
    const [ empresa, setEmpresa] = useState(data.empresa)
    const session = useSelector(state => state.miusuario)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const toast = useToast()

    useEffect(() => {
        obtenerDatos()
    }, [])

    const crearProductoNuevo = async () => {
        const requeridos = [
            { value:'titulo', label: 'Título' },
            { value:'sku', label: 'Sku' },
            { value:'descripcion', label: 'Descripción' },
            { value:'precio', label: 'Precio' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!producto[campo.value]) faltantes.push(campo.label)
            return true
        })

        if(faltantes.length > 0) return toast({ title: `Faltan campos: ${faltantes.join(', ')}`, status: "error"})

        if(producto.precio_oferta){
            if(parseFloat(producto.precio_oferta) >= parseFloat(producto.precio)) return toast({ title: `Precio oferta no puede ser superior o igual al precio normal`, status: "error"})
        }
        setLoadingCreacion(true)
        return fetch(`${urlapi}/productos`,{
            method:'POST',
            body: JSON.stringify(producto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Creado exitosamente", status: "success" })
                setProducto({...{}, ...{ stock_type: "simple" }})
                onClose()
                setResetListado(resetListado + 1)
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingCreacion(false)
        })

    }

    const obtenerDatos = async (e) => {
        setLoading(true)
        const url = `${urlapi}/auth/cuenta`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                setUsuario(res)
                if(res.empresa){
                    setEmpresa(res.empresa)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoading(false)
        })
    }
    
    const guardarCambios = async (e) => {
        setLoadingForm(true)
        const url = `${urlapi}/auth/cuenta`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Actualizado exitosamente", status: "success" })
            }
            return setLoadingForm(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoadingForm(false)
        })
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }


    const mostrarInfo = () => {
    if(loading) return <Box p={5} mt={10}>
        <Grid templateColumns={{ md: "2fr 10fr", base: "1fr" }} gap={4} >

        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        </Grid>
    </Box>

    if(!usuario) return <Box p={5} mt={10}>
        <Heading mb={2}>Sin datos para mostrar esta sección</Heading>
    </Box>

    return <Box p={5} mt={10}>
    <Grid templateColumns={{ md: "12fr", base: "1fr" }} gap={4} >
      <GridItem >
        <Box pt={3}>
          <Heading mb={2} size="lg" lineHeight={8}>{empresa.razon_social}</Heading>
        <Heading size="sm" >{formatearRUTChileno(empresa.rut)}</Heading>
        <Text lineHeight={8} >{empresa.direccion ? empresa.direccion.toUpperCase() : 'Sin información'}</Text>

        <Button size="xs" variant="outline" >{stringByStatus(empresa.status)}</Button>
        </Box>
      </GridItem>
      <GridItem >
        <Card mt={3} >
        <CardBody>
            <StarIcon w={6} />
        <Heading >Datos de mi cuenta</Heading>
        <Text>En esta sección verás tus datos personales y de seguridad</Text>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={5} spacing={5}>
          <Box>
            <FormLabel>Nombres</FormLabel>
            <Input mb={3} variant="filled" name="nombres" defaultValue={usuario.nombres} onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Apellidos</FormLabel>
            <Input mb={3} variant="filled" name="apellidos" defaultValue={usuario.apellidos} onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Email</FormLabel>
            <Input mb={3} variant="filled" name="email" defaultValue={usuario.email} onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Móvil</FormLabel>
            <Input mb={3} variant="filled" name="movil" defaultValue={usuario.movil} onChange={handleChangeUsuario} />
          </Box>
        </SimpleGrid>
        <Button isLoading={loadingForm} colorScheme={tremus_style.colorButtonPrimary} onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
        </CardBody>
        </Card>

      </GridItem>
    </Grid>
    </Box>
  }

  const handleChangeProduct = (e) => {
    const { name, value } = e.target
    producto[name] = value
    console.log(producto)
    setProducto({...{}, ...producto})
  }

  const crearNuevo = () => {
    if(!permission) return null
    return <div>
        <ButtonGroup>
        <Button size="xs" leftIcon={<AiOutlinePlus /> } variant="outline" ref={btnRef} onClick={onOpen}>CREAR NUEVO</Button>
        <Button size="xs" leftIcon={<RiFileExcelLine /> } variant="outline" ><Link to={rutas.products_import.slug}>IMPORTAR EXCEL DE PRODUCTOS</Link> </Button>
        <Button size="xs" leftIcon={<RiFileExcelLine /> } variant="outline" ><Link to={rutas.products_import_inventory.slug}>IMPORTAR EXCEL DE INVENTARIO</Link> </Button>
        </ButtonGroup>
        <Drawer
        isOpen={isOpen}
        size="lg"
        placement='right'
        finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent >
          <DrawerHeader>Crear un producto</DrawerHeader>
            <DrawerBody>
          <SimpleGrid columns={1} spacing={5}>
          <SimpleGrid columns={2} spacing={5}>
            <Box>
                <FormLabel>Nombre</FormLabel>
                <Input name="titulo" value={producto.titulo} onChange={handleChangeProduct} />
            </Box>
            <Box>
                <FormLabel>Sku</FormLabel>
                <Input name="sku" value={producto.sku} onChange={handleChangeProduct} />
            </Box>
          </SimpleGrid>
            <Box>
                <FormLabel>Tipo de inventario</FormLabel>
                <Select name="stock_type" value={producto.stock_type} placeholder='Selecciona una opción' onChange={handleChangeProduct}>
                    <option value="simple">Sin manejo de inventarios</option>
                    <option value="managed">Activar manejo de inventarios</option>
                </Select>
            </Box>
            
            <Box>
            <SimpleGrid columns={2} spacing={5}>
            <Box>
                <FormLabel>Precio</FormLabel>
                <Input name="precio" value={producto.precio} type='number' onChange={handleChangeProduct} />
            </Box>
                <Box>
                    <FormLabel>Precio oferta</FormLabel>
                    <Input name="precio_oferta" value={producto.precio_oferta} onChange={handleChangeProduct} />
                </Box>
                
            </SimpleGrid>
            </Box>
            <Box>
                    <FormLabel>Descripción</FormLabel>
                    <Textarea name='descripcion' value={producto.descripcion} placeholder='' onChange={handleChangeProduct} />
                </Box>
          </SimpleGrid>            
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              CANCELAR
            </Button>
            <Button isLoading={loadingCreacion} colorScheme={tremus_style.colorButtonPrimary} onClick={() => crearProductoNuevo()} >CREAR</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  }

  const checkPermisson = (objeto, clave) => {
    let keys = Object.keys(objeto);
    let index = keys.indexOf(clave);

    if (index === -1) {
        return false;
    }

    // Verificamos las claves anteriores
    for (let i = 0; i < index; i++) {
        if (!objeto[keys[i]]) {
            return false;
        }
    }

    return true
  }

  const revisarConfig = (objeto, clave) => {
    const item = checkPermisson(objeto, clave)
    setPermision(item)
  }

  const render = () => {
    return <Box>
    <Box bg={tremus_style.bg_default} p={5}>
    
    <Grid templateColumns={{ md: "12fr", base: "1fr" }} gap={4}>
      <GridItem >
        <Card mt={3} style={{ marginBottom: -60 }}>
        <CardBody>
        <Heading ><AiOutlineShop /> Productos</Heading>
        {crearNuevo()}
        </CardBody>
        </Card>
      </GridItem>
    </Grid>
    </Box>
    <Box p={5} mt={10}>
    <Grid templateColumns={{ md: "6fr", base: "1fr" }} gap={4} >
    {/* { !permission ? <StatusBusiness can_i_step="cargar_producto"  onGetData={config => setPermision(config.status === true)} /> : null } */}
    {/* { permission ?  : null }     */}
    <ListadoProductos reset={resetListado} />
    </Grid>
    </Box>
  </Box>
  }

  return <Structure component={render()} />
}

export default Productos
