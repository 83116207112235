import { Box, Button, ButtonGroup, Card, CardBody, Checkbox, Flex, Grid, GridItem, Heading, Image, Input, InputGroup, InputLeftElement, Select, SimpleGrid, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Cargando from "./cargando"
import { cerrarSesion } from "../../redux/actions/sesion"
import datos, { url_images, urlapi } from "../../lib/backend/data"
import { formatoMoneda, formatoNumero } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { AiOutlineSearch } from 'react-icons/ai'
import { GrPowerReset } from "react-icons/gr"
import { stringByStatus } from "../../lib/helpers/productos"
import { statuses_product } from "../../lib/data/products"
// import Pagination from "react-bootstrap/Pagination";
import { Pagination, PaginationItem, ThemeProvider, createTheme } from "@mui/material";
import { DateTime } from "luxon"
import ModalInformacion from "./modalInfo"

const ListadoProductos = (props) => {
    const {
        condicion_default,
        typeSelection,
        reset
    } = props
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ hitos, setHitos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ filtros, setFiltros] = useState([])
    const [ conductores, setConductores] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ searching, setSearching ] = useState(false)
    const [ allowResetSearch, setAllowResetSearch ] = useState(true)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ empresa, setEmpresa] = useState(data.empresa)
    const [verificar, setVerificar] = useState([]);
    const session = useSelector(state => state.miusuario)
    const toast = useToast()

    const openNotification = (message, description, status) => {
        const estado = status ? status : "error"
        return toast({ title: message, description, status: estado })
    }

    const obtenerConductores = async (page, query, buscando)=>{
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/productos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            }
            if(Array.isArray(res.datos) !== false){
                if(res.hitos) setHitos(res.hitos)
                if(buscando === true ) if(res.datos.length < 1) openNotification("Sin resultados")
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            setSearching(false)
            return setLoading(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            setSearching(false)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [ reset ])

    const buscarRegistro = () => {
        let cond = { sku: { $regex: campoBusqueda.toLowerCase(), $options: "i" } }
        if(!campoBusqueda) cond = {}
        setCondicionBusqueda(cond)
        setPagina(1)
        setSearching(true)
        setAllowResetSearch(false)
        return obtenerConductores(1, cond, true)
    }

    const reiniciarBusqueda = () => {
        let cond = {  }
        setCondicionBusqueda(cond)
        setPagina(1)
        setSearching(true)
        setAllowResetSearch(true)
        return obtenerConductores(1, cond)
    }

    const handleChangeBusqueda = (e) => {
        return setCampoBusqueda(e.target.value)
    }

 
    //FUNCION DE PAGINACION VIEJA
    // const paginacion = (ciclo, total) => {
    //     const cantidad = Math.ceil(total / ciclo);
    //     return (
    //       <div className="anterior">
    //         <Pagination size="sm">
    //           {Array.from(Array(cantidad).keys()).map((number) => {
    //             const active = pagina === number + 1 ? true : false;
    //             return (
    //               <Pagination.Item
    //                 key={number + 1}
    //                 active={active}
    //                 onClick={() => paginar(number + 1, active)}
    //               >
    //                 {number + 1}
    //               </Pagination.Item>
    //             );
    //           })}
    //         </Pagination>
    //       </div>
    //     );
    //   }

    const paginar = (page) => {
        if (page === pagina) return false;
        setPagina(page);
        setSearching(true)
        obtenerConductores(page);
      };

    const handleChange = (event, value) => {
        paginar(value);
      };

    const paginacion = (ciclo, total) => {
        const theme = createTheme({
            // Puedes personalizar tu tema aquí
          });
        
        const cantidad = Math.ceil(total / ciclo);
        return (
          <Box mb={3} className="anterior">
            <ThemeProvider theme={theme}>
            <Pagination disabled={searching} count={cantidad} defaultPage={pagina} onChange={handleChange} size="sm">
            </Pagination>
            </ThemeProvider>
          </Box>
        );
      }

    const buscador = () => {
        return <div style={{ marginBottom: 20 }}>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={{ base: 12, md: 4 }}>
                <Input placeholder="Buscar" onChange={handleChangeBusqueda} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 4 }}>
                    <ButtonGroup>
                    <Button isLoading={searching} leftIcon={<AiOutlineSearch />} onClick={() => buscarRegistro()} >  BUSCAR</Button>
                    <Button isDisabled={allowResetSearch} isLoading={searching} leftIcon={<GrPowerReset />} onClick={() => reiniciarBusqueda()} >  REINICIAR BÚSQUEDA</Button>
                    </ButtonGroup>
                </GridItem>
            </Grid>
        </div>
    }
    const mostrarFiltros = () => {
        return <div>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={{ base: 12, md: 3 }}>
                <Select placeholder="Filtrar por estado">
                    <option value="">Todos</option>
                    {
                        statuses_product.map((status,i) => <option valur={status}>{stringByStatus(status)}</option>)
                    }
                </Select>
                </GridItem>
            </Grid>
        </div>
    }

    const pantallaSinDatos = () => {
        return <Box textAlign="center"  >
            <div style={{ textAlign: "center" }}>
            <Heading as="h2">Aquí verás tus productos</Heading>
            <p>¡Adelante! Comienza a cargarlos</p>
            <img style={{ margin: "0 auto", maxWidth: 300 }} src={`${url_images}/illustrations/select-product.svg`} />
            </div>
        </Box>
    }

    const exportarDatos = (pro) => {
        if(props.exportarDatos) props.exportarDatos(pro)
        console.log(pro)
    }

    const mostrarPorTipo = (producto) => {
        switch (typeSelection) {
            case "function":
                return <div className="hover" onClick={() => exportarDatos(producto)}>{producto.title}</div>
            default:
                return producto.title
        }
    }

    const changeVerificarTodo = (e) => {
        const checked = e.target.checked;
        if (checked) {
          setVerificar([...[], ...conductores]);
        } else {
          setVerificar([...[], ...[]]);
        }
      };
    
      const changeVerificar = (producto, checked) => {
        if (checked) {
          let pr = conductores.filter((conductor) => conductor._id === producto);
          setVerificar([...verificar, ...pr]);
        } else {
          let pr = verificar.filter((objeto) => objeto._id !== producto);
          setVerificar(pr);
        }
      };

      const formatoEntendibleConLuxon = (fechaISO) => {
        const fecha = DateTime.fromISO(fechaISO);
        return fecha.toRelative({ base: DateTime.now() });
    }

    const mostrarDatos = () => {
        if(conductores.length < 1) return pantallaSinDatos()
        if (loading) return <Cargando />;

        let indexado = {};
        verificar.map((con) => (indexado[con._id] = 1));

        return <div>
            <Box pt={4} pb={4}>{paginacion(datos.pagina, total)}</Box>
            <TableContainer >
            <Table variant="responsive" size="sm">
                <TableCaption></TableCaption>
                <Thead>
                <Tr>
                <Th>
                    <Checkbox
                    className="modifyCB"
                    onChange={changeVerificarTodo}
                    ></Checkbox>
                </Th>
                    <Th>Imagen</Th>
                    <Th>Nombre</Th>
                    {/* <Th>Estado</Th> */}
                    <Th>SKU</Th>
                    {
                        hitos.map((hi,it) => <Th key={`i-${it}`}>{hi.name}</Th>)
                    }
                    {/* <Th isNumeric>Precio</Th> */}
                    {/* <Th isNumeric>Precio oferta</Th> */}
                </Tr>
                </Thead>
                <Tbody>
                    {
                        conductores.map(producto => {
                            return <Tr key={`pro-${producto._id}`} >
                                <Td>
                                    <Checkbox
                                    isChecked={indexado[producto._id] ? true : false}
                                    onChange={(e) =>
                                        changeVerificar(producto._id, e.target.checked)
                                    }
                                    className="modifyCB"
                                    ></Checkbox>
                                </Td>
                                <Td ><Image maxWidth={50} src={producto.image} fallbackSrc='https://via.placeholder.com/150' mb={3} /></Td>
                                <Td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }}>{mostrarPorTipo(producto)}</Td>
                                {/* <Td>{stringByStatus(producto.status)}</Td> */}
                                <Td>{producto.sku}</Td>
                                {
                                    hitos.map((hi,it) => {
                                        const i = producto.hitos.findIndex(h => h.name === hi.value)
                                        if(i < 0) return <Td key={`h-${it}`}>Sin datos</Td>

                                        let fecha = producto.hitos[i] ? formatoEntendibleConLuxon(producto.hitos[i].updatedAt) : ""
                                        return <Td key={`h-${it}`} style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                                            <Flex align="center" justify="space-between">
                                            {fecha}
                                            <ModalInformacion info={producto.hitos[i]} />
                                            </Flex>
                                        </Td>
                                    })
                                }
                                {/* <Td isNumeric>{formatoMoneda(producto.precio)}</Td> */}
                                {/* <Td isNumeric>{formatoMoneda(producto.precio_oferta)}</Td> */}
                            </Tr>
                        })
                    }
                </Tbody>
            </Table>
            </TableContainer>
                </div>
    }

    const mostrarRegistros = () => {

        if(loading) return <Cargando />

        return <div>
            {/* <Card size="sm">
                <CardBody>
                <Grid templateColumns={{ md: "1fr 1fr 1fr 1fr 1fr", base: "1fr" }} gap={4}>
                        <GridItem >
                            <Box>
                                    <StatGroup>
                                        <Stat>
                                        <StatLabel>Total</StatLabel>
                                        <StatNumber>{formatoNumero(total)}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                        </Stat>
                                    </StatGroup>
                            </Box>
                            
                        </GridItem>
                {
                    filtros.map((filtro,i) => {
                        return <GridItem key={`f-${i}`}>
                            <Box>
                                    <StatGroup>
                                        <Stat>
                                        <StatLabel>{filtro.label}</StatLabel>
                                        <StatNumber>{formatoNumero(filtro.cantidad)}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                        </Stat>
                                    </StatGroup>
                            </Box>
                            
                        </GridItem>
                    })
                }
            </Grid>
                </CardBody>
            </Card> */}
            
            
            <Card size="sm" mt={1}>
                <CardBody>
                {buscador()}
                {mostrarFiltros()}
                {mostrarDatos()}
                </CardBody>
            </Card>
            
        </div>
    }
    return <Box>
    {mostrarRegistros()}
    </Box>
}

export default ListadoProductos