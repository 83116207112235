import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, Divider, FormLabel, Grid, GridItem, Heading, Image, Input, Select, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import Header from "../Header"
import Cargando from "./cargando"
import { fechaATexto } from "../../lib/helpers/helpers"
import { CheckCircleIcon, TimeIcon } from "@chakra-ui/icons"
import { FaBeer } from 'react-icons/fa';
import { tremus_style } from "../../lib/global/style"
import { FiAlertCircle } from "react-icons/fi";
import ListadoEventos from "../Actividad/listado"
import { keyToLabelProduct, stringByStatus } from "../../lib/helpers/productos"
import ListadoSolicitudes from "../Solicitudes/listado"
import CrearSolicitud from "../Solicitudes/crear"
import Structure from "../Structure"
import { rutas } from "../../lib/global/rutas"
import ListadoCalificaciones from "../Calificaciones/listado"
import { RiChatHistoryLine } from "react-icons/ri"
import { GrDocumentTime } from "react-icons/gr"
import { AiOutlineStar } from "react-icons/ai"
import ListadoPedidos from "../Pedidos/listado"
import CargaImagenes from "../../subcomponents/general/carga_imagenes"

const DetalleProducto = (props) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ producto, setProducto ] = useState(false)
    const [ loadingActualizacion, setLoadingActualizacion ] = useState(false)
    const [ loadingAnulacion, setLoadingAnulacion ] = useState(false)
    const [ productoOriginal, setProductoOriginal ] = useState(false)
    const [ etapas, setEtapas ] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const toast = useToast()

    const anularSolicitud = async () => {
        setLoadingAnulacion(true)
        return fetch(`${urlapi}/actividad/solicitudes/anular?id=${producto.cambios_pendientes_ficha._id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "anulado exitosamente", status: "success"})
                obtenerDatos()                
            }
            return setLoadingAnulacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingAnulacion(false)
        })
    }

    const obtenerDatos = async () => {
        if(!id) return setLoadingMaster(false)
        setLoadingMaster(true)
        return fetch(`${urlapi}/productos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                setProducto(res)
                const nueva_instancia_objeto = JSON.parse( JSON.stringify(res) )
                setProductoOriginal(nueva_instancia_objeto)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingMaster(false)
        })
    }
    
    const guardarCambios = async () => {
        
        const requeridos = [
            { value:'title', label: 'Título' },
            { value:'sku', label: 'Sku' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!producto[campo.value]) faltantes.push(campo.label)
            return true
        })

        if(faltantes.length > 0) return toast({ title: `Faltan campos: ${faltantes.join(', ')}`, status: "error"})

        let cambios = {}
        Object.keys(producto).map(key => {
            const valor_real = producto[key] ? producto[key] : ""
            if(!valor_real && !cambios[key]) return false
            if(productoOriginal[key] !== valor_real) cambios[key] = valor_real
        })
        
        if(Object.keys(cambios).length < 1) return toast({ title: `No realizaste ningún cambio`, status: "error"})

        const payload_enviar = {
            id_target: producto._id,
            valor: cambios,
            descripcion: "Cambios en la ficha del producto",
            type: "change_properties",
            ids_canales: [ "web-tremus" ]
        }

        setLoadingActualizacion(true)
        return fetch(`${urlapi}/productos`,{
            method:'PUT',
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Cambios solicitados exitosamente", status: "success" })
                return obtenerDatos()
            }
            return setLoadingActualizacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingActualizacion(false)
        })
    }

    const handleChangeProduct = (e) => {
        const { name, value } = e.target
        console.log(value)
        setProducto(prev => {
            let actual = {...prev}
            if(["precio","precio_oferta"].includes(name)){
                let numero = parseInt(value)
                actual[name] = isNaN(numero) ? '' : numero
            } else {
                actual[name] = value
            }
            return actual
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const mostrarEtapas = () => {
        if(!etapas) return false
        if(Array.isArray(etapas) !== true) return false
        if(etapas.length < 1) return false

        return <Box>
            <Grid templateColumns={{ md: "3fr 9fr", base: "1fr" }} gap={4} >
            {
                etapas.map((etapa,i) => {
                    return <Box key={`etapa-${i}`}>
                        { etapa.check === true ? <CheckCircleIcon/> : <TimeIcon /> }
                        <Heading size="md">{etapa.label}</Heading>
                        <Heading size="sm">{fechaATexto(etapa.fecha)}</Heading>
                    </Box>
                })
            }
            </Grid>
        </Box>
    }

    const mostrarAcciones = () => {
        if(producto.cambios_pendientes_ficha) return <Card background={tremus_style.colorButtonPrimary} color="white">
            <CardBody>
                <FiAlertCircle size={30} color="orange" />
                <span style={{ fontSize: 11}}>{fechaATexto(producto.cambios_pendientes_ficha.fecha_hora_local)}</span>
                <Heading size="sm">Solicitaste cambios en la ficha, debes esperar a que sean revisados o anularlos para poder enviar nuevos cambios</Heading>
                <Divider mt={3} mb={3} />
                {Object.keys(producto.cambios_pendientes_ficha.valor).map(key => <h4 style={{ fontSize: 14 }}><b>{keyToLabelProduct(key)}</b> {producto.cambios_pendientes_ficha.valor[key]}</h4>)}
                <Divider mt={3} mb={3} />
                <Button size="xs" isLoading={loadingAnulacion}  colorScheme="red" onClick={() => anularSolicitud()}>ANULAR CAMBIOS</Button>
            </CardBody>
        </Card>

        return <Button  isLoading={loadingActualizacion} colorScheme={tremus_style.colorButtonPrimary} onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
    }

    const detallesProducto = () => {
        if(loadingMaster) return <Cargando/>

        return <Grid templateColumns={{ md: "3fr 9fr", base: "1fr" }} gap={4} >
            <Box>
                <Card>
                    <CardBody>
                    <SimpleGrid columns={1} spacing={5}>
            
            <Box>
                <FormLabel>Imagen</FormLabel>
                
                {
                    producto.image ? <Image src={producto.image} mb={3} /> : false
                }
                <CargaImagenes onUploaded={(location) => handleChangeProduct({ target:{ name: "image", value: location } })} />
            </Box>
            <Box>
                <FormLabel>Nombre</FormLabel>
                <Input name="title" defaultValue={producto.title} onChange={handleChangeProduct} />
            </Box>
            <Box>
                <FormLabel>Sku</FormLabel>
                <Input name="sku" defaultValue={producto.sku} onChange={handleChangeProduct} />
            </Box>
            {/* <Box>
                <FormLabel>Tipo de inventario</FormLabel>
                <Select name="stock_type" defaultValue={producto.stock_type} placeholder='Selecciona una opción' onChange={handleChangeProduct}>
                    <option value="simple">Sin manejo de inventarios</option>
                    <option value="managed">Activar manejo de inventarios</option>
                </Select>
            </Box>
            
            <Box>
            <Box>
                <FormLabel>Precio</FormLabel>
                <Input name="precio" defaultValue={producto.precio} type='number' onChange={handleChangeProduct} />
            </Box>
            </Box> */}
            <Box>
                    <FormLabel>Descripción</FormLabel>
                    <Textarea name='descripcion' defaultValue={producto.descripcion} placeholder='' onChange={handleChangeProduct} />
                </Box>
                {mostrarAcciones()}
          
          </SimpleGrid>  
                    </CardBody>
                </Card>
            </Box>
            <Box>
                    <Tabs>
                        <TabList>
                            <Tab><Heading size="md"><RiChatHistoryLine style={{ display: "inline", verticalAlign: "middle" }} /> Actividad</Heading></Tab>
                            {/* <Tab><Heading size="md"><GrDocumentTime style={{ display: "inline", verticalAlign: "middle" }} /> Tus Solicitudes</Heading></Tab>
                            <Tab><Heading size="md"><AiOutlineStar style={{ display: "inline", verticalAlign: "middle" }} /> Calificaciones</Heading></Tab>
                            <Tab><Heading size="md"><AiOutlineStar style={{ display: "inline", verticalAlign: "middle" }} /> Pedidos</Heading></Tab> */}
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <ListadoEventos condicion_default={{ id_target: producto._id }} />
                            </TabPanel>
                            {/* <TabPanel>
                                <CrearSolicitud id_target={producto._id} tipo_recurso={"product"} onCreateNew={() => obtenerDatos()} />
                                <ListadoSolicitudes  condicion_default={{ id_target: producto._id }} />
                            </TabPanel>
                            <TabPanel>
                                <ListadoCalificaciones condicion_default={{ id_target: producto._id }} />
                            </TabPanel>
                            <TabPanel>
                                <ListadoPedidos condicion_default={{ "line_items.sku": producto.sku }} />
                            </TabPanel> */}
                        </TabPanels>
                        </Tabs>
            </Box>
        </Grid>
    }

    const BreadCrumb = () => {
        return <div>
            <Breadcrumb>
            <BreadcrumbItem>
                <BreadcrumbLink><Link to={rutas.products.slug}>{rutas.products.title}</Link> </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink >Detalles del producto</BreadcrumbLink>
            </BreadcrumbItem>
            </Breadcrumb>
        </div>
    }

   const render = () => {
    return <Box>
    <Box bg="linear-gradient(to right, #1061b7, #00b1b1)" p={5}>
    
    <Grid templateColumns={{ md: "12fr", base: "1fr" }} gap={4}>
      <GridItem >
        <Card mt={3} style={{ marginBottom: -60 }}>
        <CardBody>
        <BreadCrumb />
        <Heading >{producto ? producto.title : "Detalles del producto"}</Heading>
        <Heading size="md" >{stringByStatus(producto.status)}</Heading>
        </CardBody>
        </Card>
      </GridItem>
    </Grid>
    </Box>
    <Box p={5} mt={10}>
    
    {detallesProducto()}
    
    </Box>
  </Box>
   }

return <Structure component={render()} />
} 

export default DetalleProducto