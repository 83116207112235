import JsonView from '@uiw/react-json-view';
import { fechaATexto, fechaATextoSimple, fechaUTCATexto } from '../../lib/helpers/helpers';
const { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Heading, Divider, useDisclosure, Button, ModalFooter, Tag } = require("@chakra-ui/react")

const ModalInformacion = (props) => {
    const { info } = props
    const { isOpen, onOpen, onClose } = useDisclosure()

    return <div>
        <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader style={{ fontSize: 15 }}>Descripción del evento</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Tag size="sm" sx={{ mb:2 }}>{fechaUTCATexto(info.updatedAt)}</Tag>
        <Heading size="md" sx={{ mb:2 }}>Enviado</Heading>
        <JsonView value={info?.body} />
        <Divider mt={3} mb={3} />
        <Heading size="md" sx={{ mb:2 }}>Respuesta</Heading>
        <JsonView value={info?.response} />
        </ModalBody>
    </ModalContent>
  </Modal>
  <Button size="xs" onClick={onOpen}>VER DETALLES</Button>
    </div>
}

export default ModalInformacion