import { Box, Button, Divider, Grid, GridItem, Heading, Link, Text } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom";
import { rutas } from "../../lib/global/rutas";
import { AiOutlineHome, AiOutlineShop, AiOutlineStar } from "react-icons/ai";
import { BiLogOut, BiSolidReport } from "react-icons/bi";
import { MdAccountCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { GrDocumentTime } from "react-icons/gr";
import { FiBox } from "react-icons/fi";

const Structure = (props) => {
    const {
        component
    } = props

    const dispatch = useDispatch()
    const paddingNumber = 15
    const styleSubMenu = { fontWeight: "normal", color: "gray" }

    return <div>
        <Grid templateColumns="repeat(12, 1fr)" gap={0}>
                <GridItem colSpan={{ base: 12, md: 2 }} style={{ background: "white" }}>
                <div >
                    <Box p={3}><Heading fontSize={25} mt={3} >WESYNCRO</Heading>
                    <Text mb={3} fontSize={14}>Integra · Crece · Evoluciona</Text>
                    </Box>
                    <Link
                    as={RouterLink}
                    to={rutas.dashboard.slug}
                    fontSize="xl"
                    fontWeight="bold"
                    >
                    <Button leftIcon={<AiOutlineHome /> } variant="ghost">{rutas.dashboard.title}</Button>
                    </Link>

                    <Divider mt={2} mb={2} />
                    <div style={{ paddingLeft: paddingNumber, paddingRight: paddingNumber }}><Heading style={styleSubMenu} as="h2" size="sm">Información valiosa</Heading></div>
                    <Divider mt={2} mb={2} />



                    <Link
                        as={RouterLink}
                        to={rutas.products.slug}
                        mr={4}
                        _hover={{ textDecoration: "underline" }}
                        >
                        <Button leftIcon={<FiBox /> } variant="ghost">{rutas.products.title}</Button>
                    </Link>
                    <Link
                        as={RouterLink}
                        to={rutas.dashboard.slug}
                        mr={4}
                        _hover={{ textDecoration: "underline" }}
                        >
                        <Button leftIcon={<BiSolidReport /> } variant="ghost">{rutas.integraciones.title}</Button>
                    </Link>
                    <Divider mt={2} mb={2} />
                    <div style={{ paddingLeft: paddingNumber, paddingRight: paddingNumber }}><Heading as="h2" style={styleSubMenu} size="sm">Cuenta</Heading></div>
                    <Divider mt={2} mb={2} />

                    <Link
                        as={RouterLink}
                        to={rutas.cuenta.slug}
                        mr={4}
                        _hover={{ textDecoration: "underline" }}
                        >
                        <Button leftIcon={<MdAccountCircle /> } variant="ghost">{rutas.cuenta.title}</Button>
                    </Link>
                    <Link
                        mr={4}
                        _hover={{ textDecoration: "underline" }}
                        onClick={() => dispatch(cerrarSesion())}
                        >
                        <Button colorScheme="red" leftIcon={<BiLogOut /> } variant="ghost">Cerrar sesión</Button>
                    </Link>
                </div>
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 10 }} style={{ height: "100vh", overflowY: "scroll" }}>
                    {component}
                </GridItem>
            </Grid>
    </div>
}

export default Structure