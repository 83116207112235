export const statuses_product = [
    "pending",
    "published",
    "rejected",
    "unpublished",
    "reviewing",
]

export const statuses_order = [
    "pending",
    "prepared",
    "transit",
    "delivered",
    "undelivered"
]